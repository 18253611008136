import '../scss/main.scss';

import $ from "jquery";
import gsap from "gsap";
import Swiper, {Navigation, Keyboard} from 'swiper';

// configure Swiper to use modules
Swiper.use([Navigation, Keyboard]);

//INIT ALL FUNCTIONS
animations();
scrollSmooth();
nav();
sliders();
accordions();
spacerFlex();
launchVideo();
singleProduct();

function animations() {
    const page = document.getElementById('page');

    // reset position of the loading screen
    gsap.set(page, {
        x: 40,
        autoAlpha: 0
    });

    document.addEventListener("DOMContentLoaded", function (event) {
        gsap.to(page, 0.8, {
            x: 0, autoAlpha: 1, ease: 'Expo.out', onComplete: () => {
                intersectionObserver();
            }
        }, 0.8);
    });
}

function nav() {
    const body = document.body, buttonBurger = document.querySelector('button.burger--button'),
        burgerMenu = document.querySelector('aside.burger-menu'),
        buttonSearch = document.querySelector('button.search--button'),
        buttonCloseSearch = document.querySelector('aside.panel--search .panel--search-icon'),
        panelSearch = document.querySelector('aside.panel--search'),
        menuItemsChildren = document.querySelectorAll('aside.burger-menu > .container ul.menu > li.menu-item.menu-item-has-children > a');

    //Nav
    buttonBurger.addEventListener('click', function () {
        body.classList.toggle('menu-open');
        buttonBurger.classList.toggle('active');
        burgerMenu.classList.toggle('active');
        return false;
    });

    menuItemsChildren.forEach(function (item, index) {
        item.addEventListener('click', function () {
            item.parentElement.classList.toggle('active');
            return false;
        });
    });

    //Search
    buttonSearch.addEventListener('click', function () {
        panelSearch.classList.add('active');
        body.classList.add('search-open');
        document.querySelector('aside.panel--search input').select();
        return false;
    });

    buttonCloseSearch.addEventListener('click', function () {
        panelSearch.classList.remove('active');
        body.classList.remove('search-open');
        return false;
    });
}

function scrollSmooth() {
    //Smooth scrolling with links

    document.querySelectorAll('a[href*=\\#]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            if (!e.target.hasAttribute('target')) {
                const element = document.querySelector(e.target.hash), headerOffset = 100,
                    elementPosition = element.getBoundingClientRect().top,
                    offsetPosition = elementPosition + window.pageYOffset - headerOffset;

                window.scrollTo({
                    top: offsetPosition, behavior: "smooth"
                });
            }
        });
    });

    // Smooth scrolling when the document is loaded and ready
    if (window.location.hash) {
        const element = document.querySelector(window.location.hash), headerOffset = 100,
            elementPosition = element.getBoundingClientRect().top,
            offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
            top: offsetPosition, behavior: "smooth"
        });
    }
}

function launchVideo() {
    let video_iframe = $('.popin-video-home iframe'), popin_video_home = $('.popin-video-home');

    $('.open-popin-video').click(function () {
        popin_video_home.addClass('active');

        if (window.matchMedia("(min-width: 1181px)").matches) {
            var videoURL = video_iframe.prop('src');
            videoURL += "&autoplay=1";
            video_iframe.prop('src', videoURL);
        }
        return false;
    });

    $('.close-popin-video').click(function () {
        popin_video_home.removeClass('active');
        if (window.matchMedia("(min-width: 1181px)").matches) {
            var videoURL = video_iframe.prop('src');
            videoURL = videoURL.replace("&autoplay=1", "");
            video_iframe.prop('src', '');
            video_iframe.prop('src', videoURL);
        }
        return false;
    });

    $('.block-quai13--double-column-evo__video---mobile .play-video').click(function () {
        $(this).addClass('hide');
        $(this).parent().find('video')[0].currentTime = 0;
        $(this).parent().find('video')[0].play();
        return false;
    });

    $('.block-quai13--double-column-evo__video---mobile video').click(function () {
        $(this).parent().find('.play-video').removeClass('hide');
        $(this).parent().find('video')[0].pause();
        return false;
    });

    if (window.matchMedia("(min-width: 1181px)").matches) {
        const videos = document.querySelectorAll('.nav-video video');
        videos.forEach(function (video, index) {
            video.currentTime = 0;
            video.play();
        });
    }
}

function sliders() {

    if (document.querySelector('.block-quai13--slider-our-products')) {
        // Slider "Our Products"
        const containerSliderOurProducts = new Swiper('.block-quai13--slider-our-products__wrapper---products', {
            loop: true, centeredSlides: true, spaceBetween: 40, slidesPerView: 'auto', keyboard: {
                enabled: true, onlyInViewport: true,
            }, navigation: {
                nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev',
            }, breakpoints: {
                600: {
                    spaceBetween: 120
                }
            }
        });
    }

    if (document.querySelector('.block-quai13--slider-posts')) {
        // Slider "Posts"
        const containerSliderPosts = new Swiper('.block-quai13--slider-posts__wrapper---posts', {
            loop: false, slidesPerView: 1, width: 300, spaceBetween: 10, breakpoints: {
                768: {
                    width: 350, spaceBetween: 50
                }, 1025: {
                    width: 450, spaceBetween: 150, keyboard: {
                        enabled: true, onlyInViewport: true,
                    }, navigation: {
                        nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev',
                    }
                }
            }
        });
    }


    if (document.querySelector('.block-quai13--clients')) {
        // Slider "Clients"
        const containerSliderClients = new Swiper('.block-quai13--clients__wrapper---clients', {
            slidesPerView: 2, spaceBetween: 50, keyboard: {
                enabled: true, onlyInViewport: true,
            }, navigation: {
                nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev',
            }, breakpoints: {
                600: {
                    slidesPerView: 3
                }, 1025: {
                    slidesPerView: 6
                }
            }
        });
    }


    if (document.querySelector('.block-quai13--single-product__other_products_by_same_category')) {
        // Slider "Other Products by Same Category"
        const containerSliderOtherProductsBySameCategory = new Swiper('.block-quai13--single-product__wrapper---products', {
            loop: true, slidesPerView: 1, centeredSlides: true, spaceBetween: 50, keyboard: {
                enabled: true, onlyInViewport: true,
            }, navigation: {
                nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev',
            }, breakpoints: {
                850: {
                    slidesPerView: 3, spaceBetween: 30,
                }
            }
        });
    }
}

function accordions() {
    if (document.querySelectorAll('.field-accordion')) {
        var accordion = document.querySelectorAll('.accordion-item');

        accordion.forEach(function (element, index) {
            element.addEventListener('click', function () {

                //Bail if our clicked element doesn't have the class
                var title = element.querySelector('.accordion-toggle');
                if (!title) return;

                // Get the target content
                var content = element.querySelector('.accordion-content');
                if (!content) return;

                // Prevent default link behavior
                event.preventDefault();

                // If the content is already expanded, collapse it and quit
                if (element.classList.contains('active')) {
                    element.classList.remove('active');
                    title.classList.remove('active');
                    content.classList.remove('active');
                    return;
                }

                // Get all open accordion content, loop through it, and close it
                var accordions = document.querySelectorAll('.accordion-item.active'),
                    accordions_title = document.querySelectorAll('.accordion-toggle.active'),
                    accordions_content = document.querySelectorAll('.accordion-content.active');
                if (accordions && accordions_title && accordions_content) {
                    for (let i = 0; i < accordions.length; i++) {
                        accordions[i].classList.remove('active');
                    }
                    for (let j = 0; j < accordions_title.length; j++) {
                        accordions_title[j].classList.remove('active');
                    }
                    for (let k = 0; k < accordions_content.length; k++) {
                        accordions_content[k].classList.remove('active');
                    }
                }

                // Toggle our content
                element.classList.toggle('active');
                title.classList.toggle('active');
                content.classList.toggle('active');
            });
        });
    }
}

function spacerFlex() {
    if (window.matchMedia("(min-width: 769px)").matches) {
        if ($('.block-quai13--tax-product').length) {
            const container_block = $('ul.block-quai13--tax-product__products'), nb_items_row_3 = 3, counter_for = [];

            for (let j = 1; j <= nb_items_row_3; j++) {
                counter_for[j] = container_block.append('<li class="block spacer"></li>')
            }
        }

        if ($('.block-quai13--list-posts').length) {
            const container_block = $('ul.block-quai13--list-posts__wrapper---posts'), nb_items_row_3 = 3,
                counter_for = [];

            for (let j = 1; j <= nb_items_row_3; j++) {
                counter_for[j] = container_block.append('<li class="block spacer"></li>')
            }
        }
    }
}

function intersectionObserver() {
    const fieldImages = document.querySelectorAll('.js-anim-image'),
        fieldContent = document.querySelectorAll('.field-content > p, .field-content > ul'),
        fieldTitles = document.querySelectorAll('.field-title-h2'), config = {
            root: null, // sets the framing element to the viewport
            rootMargin: '0px', threshold: 0.5
        }, observer = new window.IntersectionObserver(function (entries) {
            entries.forEach(function (item) {
                if (item.intersectionRatio) {
                    item.target.classList.add("active");
                }
            });
        }, config)

    fieldImages.forEach(function (item) {
        observer.observe(item);
    });

    if (!document.body.classList.contains('single-product')) {
        fieldContent.forEach(function (item) {
            observer.observe(item);
        });
    }

    fieldTitles.forEach(function (item) {
        observer.observe(item);
    });
}

function singleProduct() {
    let formCart = $('form.cart');

    formCart.on('click', 'button.plus, button.minus', function () {
        // Get current quantity values
        var qty = $(this).closest('form.cart, .quantity').find('.qty');
        var val = parseFloat(qty.val());
        var max = parseFloat(qty.attr('max'));
        var min = parseFloat(qty.attr('min'));
        var step = parseFloat(qty.attr('step'));

        // Change the value if plus or minus
        if ($(this).is('.plus')) {
            if (max && (max <= val)) {
                qty.val(max);
            } else {
                qty.val(val + step);
            }
        } else {
            if (min && (min >= val)) {
                qty.val(min);
            } else if (val > 1) {
                qty.val(val - step);
            }
        }
    });

    $(document).on('click', '.plus', function (e) { // replace '.quantity' with document (without single quote)
        const $input = $(this).closest('.product-quantity').find('input.qty');
        var val = parseInt($input.val());
        var step = $input.attr('step');
        step = 'undefined' !== typeof (step) ? parseInt(step) : 1;
        $input.val(val + step).change();
        document.getElementsByName('update_cart')[0].disabled = false;
    });
    $(document).on('click', '.minus',  // replace '.quantity' with document (without single quote)
        function (e) {
            const $input = $(this).closest('.product-quantity').find('input.qty');
            var val = parseInt($input.val());
            var step = $input.attr('step');
            step = 'undefined' !== typeof (step) ? parseInt(step) : 1;
            if (val > 0) {
                $input.val(val - step).change();
            }
            document.getElementsByName('update_cart')[0].disabled = false;
        });

    $('#finest-area .finest-continue').click(function () {
        $('body').removeClass('finest-body-show');
        $('#finest-area').removeClass('finest-area-show');
    });

    if (document.body.classList.contains('single-product') && !document.querySelector('.product_cat-nos-coffrets-degustation') && !document.querySelector('.product_cat-surprise-and-delight')) {
        $('.price, .woocommerce-product-details__short-description').wrapAll('<div class="infos"></div>');
        $('.price, .price_kg').wrapAll('<div class="prices"></div>');
        $('form.cart, .field-accordion, .block-quai13--single-product__complementary---list').wrapAll('<div class="wrapper_cols"></div>');
        formCart.wrapAll('<div class="col"></div>');
        $('.field-accordion, .block-quai13--single-product__complementary---list').wrapAll('<div class="col"></div>');
        $('button.minus, button.plus, .quantity').wrapAll('<div class="wrapper_qty"></div>');
        $('.block-quai13--single-product > .container > .product:not(.product_cat-nos-coffrets-degustation, .product_cat-surprise-and-delight) .product_title').addClass('field-title-h2 field-no-anim');
        $('.block-quai13--single-product > .container > .product form.cart .select-wrapper.pa_poids select option[value="350G"]').prop("selected", true);
    } else {
        $('.price, .woocommerce-product-details__short-description').wrapAll('<div class="infos"></div>');
        $('form.cart, .block-quai13--single-product__complementary---list').wrapAll('<div class="wrapper_form"></div>');

        let imageDropdown = $('.image-dropdown');

        imageDropdown.each(function (i, el) {
            $(el).click(function () {
                $(this).toggleClass('active').siblings().removeClass('active');
                return false;
            });

            $(el).find('.image-dropdown-content .radio-wrapper label:not([for=product-1])').click(function () {
                imageDropdown.find('input[type="radio"]').removeAttr('checked');
                $(this).parent().find('input[type="radio"]').attr('checked', 'checked');
                const valInput = $(this).parent().find('input[type="radio"]').val();
                $(this).parent().parent().parent().find('label[for=product-1]').removeClass('show').addClass('hide');
                $(this).parent().parent().parent().find('label.result').removeClass('hide').addClass('show').text(valInput);
                $(this).parent().parent().parent().find('input[type="hidden"]').val(valInput);
            });
        });

        $(document).on('click', 'body', function (event) {
            imageDropdown.removeClass('active');
        });
    }
}